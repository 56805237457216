<template>
    <div id="qcap_group">
        <v-row justify="center">
            <v-col cols="10">
                <v-form v-if="questions.length > 0">
                    <div v-for="(question, index) in questions" :key="index">
                        <qcap-question :question="question"
                                       :content="content"
                                       :qcap="qcap"
                                       :question-count="questions.length"
                                       :question-number="index + 1"
                                       v-on:answer="handleAnswer"
                                       v-on:notify="uploadNotification"></qcap-question>
                        <v-divider class="mb-6"></v-divider>
                    </div>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeout">
            {{snackbarMessage}}
        </v-snackbar>
    </div>
</template>

<script>
    import {mdiCircleOutline, mdiCheckCircle, mdiContentSave, mdiHelp, mdiArrowLeft, mdiArrowRight} from '@mdi/js'
    import QcapQuestion from './QcapQuestion.vue'

    if (!String.prototype.isInList) {
        String.prototype.isInList = function () {
            let value = this.valueOf();
            for (let i = 0, l = arguments[0].length; i < l; i += 1) {
                if (arguments[0][i] === value) return true;
            }
            return false;
        }
    }

    export default {
        name: 'QcapGroup',
        props: {
            questions: Array,
            content: Object,
            qcap: Object,
        },
        components: {
            'qcap-question': QcapQuestion,
        },
        data: () => ({
            timeout: 3000,
            snackbar: false,
            snackbarMessage: '',
            icons: {
                mdiCheckCircle,
                mdiCircleOutline,
                mdiContentSave,
                mdiHelp,
                mdiArrowLeft,
                mdiArrowRight
            }
        }),
        methods: {
            uploadNotification(message) {
                this.snackbarMessage = message;
                this.snackbar = true;
                this.handleAnswer();
            },
            handleAnswer: function (triggerSave) {
                this.$emit('answer', triggerSave);
            },
            scrollToTop() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            },
        }
    }
</script>
