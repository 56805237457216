<template>
    <div class="question my-3" :id="question.question_id">
        <v-dialog v-model="dialog_delete_file" max-width="400px">
            <v-card>
                <v-card-title>{{ content.keys.delete_file }}</v-card-title>
                <v-card-text>{{ this.content.keys.confirm_generic }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" text @click="dialog_delete_file = false">{{ content.keys.button_no }}</v-btn>
                    <v-btn color="secondary" text @click="deleteFile(target_file_id, target_file_original)">{{
                        content.keys.button_yes }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="d-flex">
            <div class="q-number d-none d-sm-flex headline align-self-center text-no-wrap">{{ questionNumber }} / {{
                questionCount }}
            </div>
            <div class="elements">
                <p class="body-1">{{ question.currentIteration.followup_question }}</p>
                <v-textarea
                        auto-grow
                        rows="4"
                        outlined
                        :placeholder="content.keys.placeholder_text_answer"
                        required
                        :hide-details="question.currentIteration.allow_uploads ? 'auto' : false"
                        :rules="requiredRule"
                        v-model.trim="question.currentIteration.followup_answer"
                        @change="handleAnswer(false)"
                        class="mt-3"></v-textarea>
                <v-chip-group
                        v-if="question.currentIteration.allow_uploads"
                        column class="my-3" :key="filesKey">
                    <v-chip small close v-for="upload in question.currentIteration.uploads"
                            :key="upload.id"
                            @click="downloadFile(upload.id, upload.original)"
                            @click:close="confirmFileDelete(upload.id, upload.original)"
                            color="info">
                        <v-icon left>{{ icons.mdiCheck }}</v-icon>
                        {{ upload.original }}
                    </v-chip>
                </v-chip-group>
                <v-file-input
                        v-if="question.currentIteration.allow_uploads"
                        multiple
                        label="Files"
                        outlined
                        :placeholder="content.keys.placeholder_files"
                        v-model="files"
                        show-size
                        chips
                        class="mt-3"
                        @change="uploadFiles">
                    <template v-slot:progress>
                        <v-progress-linear :value="progress"></v-progress-linear>
                    </template>
                </v-file-input>
            </div>
        </div>
    </div>
</template>

<script>

    import api from '../core-api'
    import {mdiCheck} from '@mdi/js'

    if (!String.prototype.isInList) {
        String.prototype.isInList = function () {
            let value = this.valueOf();
            for (let i = 0, l = arguments[0].length; i < l; i += 1) {
                if (arguments[0][i] === value) return true;
            }
            return false;
        }
    }

    export default {
        name: 'QcapQuestion',
        props: {
            question: Object,
            content: Object,
            qcap: Object,
            questionCount: Number,
            questionNumber: Number,
        },
        created() {
            if (!this.question.currentIteration.followup_answer) {
                this.question.currentIteration.followup_answer = null;
            }
            if (!this.question.currentIteration.answered) {
                this.question.currentIteration.answered = false;
            }
            if (this.question.currentIteration.allow_uploads) {
                if (!Array.isArray(this.question.currentIteration.uploads)) {
                    this.question.currentIteration.uploads = [];
                }
            } else if (this.question.currentIteration.uploads) {
                delete this.question.currentIteration.uploads;
            }
        },
        data: () => ({
            files: [],
            uploading: false,
            dialog_delete_file: false,
            filesKey: 0,
            progress: 0,
            target_file_id: '',
            target_file_original: '',
            requiredRule: [
                v => !!v || 'Required',
            ],
            arrayRule: [
                v => v.length > 0 || 'Required',
            ],
            icons: {
                mdiCheck,
            }
        }),
        computed: {
            uploadPending() {
                return (Array.isArray(this.files) && this.files.length > 0)
            },
        },
        methods: {
            handleAnswer(triggerSave) {
                this.question.currentIteration.answered = !!this.question.currentIteration.followup_answer;
                this.question.currentIteration.ts_answered = new Date();
                this.$emit('answer', triggerSave);
            },
            async downloadFile(id, original) {
                const url = await api.getSignedUrl(id, original);
                if (url) {
                    window.location.href = url;
                }
            },
            confirmFileDelete(id, original) {
                this.target_file_id = id;
                this.target_file_original = original;
                this.dialog_delete_file = true;
            },
            deleteFile(id, original) {
                this.dialog_delete_file = false;
                api.deleteFile(id, original).then(result => {
                    if (result.success) {
                        for (let i = 0; i < this.question.currentIteration.uploads.length; i += 1) {
                            if (this.question.currentIteration.uploads[i].id === id) {
                                this.question.currentIteration.uploads.splice(i, 1);
                            }
                        }
                        this.handleAnswer(true);
                        this.filesKey += 1;
                    }
                }).catch(err => {
                    console.error('deleteFile', err);
                });
            },
            uploadProgress(progress) {
                this.progress = progress;
            },
            async uploadFiles() {
                if (this.uploadPending) {
                    this.uploading = true;
                    let result = await api.storeFiles(this.files, this.qcap.id, this.qcap.saq_id, this.qcap.respondent_id, this.qcap.supplier_id, this.uploadProgress);
                    if (result.length > 0) {
                        this.files = [];
                        let succeeded = true;
                        for (const item of result) {
                            if (item.success) {
                                this.question.currentIteration.uploads.push({
                                    id: item.id,
                                    original: item.original,
                                });
                            } else {
                                succeeded = false;
                            }
                        }
                        if (!succeeded) {
                            this.$emit('notify', this.content.keys.notification_upload_failure);
                        }
                    }
                    this.handleAnswer(true);
                    this.uploading = false;
                    this.filesKey += 1;
                }
            }
        },
    }
</script>

<style scoped>
    .q-number {
        min-width: 100px;
        opacity: .5;
    }

    .elements {
        width: 100%;
    }
</style>
