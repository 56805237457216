import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../ui.sass';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: {
        primary: '#183d72',
        secondary: '#8898aa',
        accent: '#1c84c6',
        error: '#aa0000',
        info: '#1c84c6',
        success: '#228b22',
        warning: '#ff8c00',
      },
      dark: {
        primary: '#183d72',
        secondary: '#8898aa',
        accent: '#1c84c6',
        error: '#aa0000',
        info: '#1c84c6',
        success: '#228b22',
        warning: '#ff8c00',
      }
    }
  }
});
