<template>
  <v-app dark id="saq">
    <v-app-bar app color="primary" src="https://cdn.ethosevaluate.net/core/assets/mesh_white_op25.svg" dark>
      <v-toolbar-title v-text="title"></v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" class="text-center">
            <p class="display-1">{{ content.welcome }}</p>
            <p class="subtitle-1">{{ content.instruction }}</p>
            <v-alert v-if="isInvalid" type="error">
              The ID is incorrect or isn't active.
            </v-alert>
            <v-alert v-if="isClosed" type="warning">
              The follow-up is already answered or closed.
            </v-alert>
            <v-alert v-if="isError" type="error">
              An error occurred. Please try again later.
            </v-alert>
            <div class="my-3">
              <v-text-field
                  v-model.trim="qcap_id"
                  label="ID"
                  placeholder="QCAP ID"
                  outlined
                  :rules="idRule"
                  required
                  @change="valid_id = null"
                  @keyup="valid_id = null"
                  @keyup.enter="start()"></v-text-field>
              <v-btn :loading="loading" @click="start" color="info">Start</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app color="primary" dark>
      <div class="my-1">
        <a href="https://www.evaluate.se" target="_blank">
          <v-img position="left" src="https://cdn.ethosevaluate.net/core/assets/logo_white.svg"
                 height="30" contain></v-img>
        </a>
      </div>
      <v-spacer></v-spacer>
      <span class="white--text caption">
                &copy; {{ year }} Ethos Evaluate AB
                |
                <a href="https://tool.ethosevaluate.com/info/privacypolicy" target="_blank" style="color: #fff;">Privacy Policy</a>
            </span>
    </v-footer>

  </v-app>
</template>

<script>
import router from '../router'
import api from '../core-api'

export default {
  name: 'Start',
  created() {
    if (this.$route.query.mode) {
      if (this.$route.query.mode.toLowerCase() === 'view') {
        this.readOnly = true;
      }
    }
    if (this.init_id) {
      this.qcap_id = (this.readOnly) ? 'readonly' : this.init_id;
      this.start();
    }
  },
  mounted() {
    this.$gtag.screenview({
      app_name: 'Evaluate QCAP',
      screen_name: 'Start',
    });
  },
  computed: {
    init_id() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    title: 'Questionnaire Follow-up',
    content: {
      'welcome': 'Welcome!',
      'instruction': 'To start, enter your ID.'
    },
    qcap_id: '',
    qcap: {},
    readOnly: false,
    loading: false,
    year: new Date().getFullYear().toString(),
    isInvalid: false,
    isError: false,
    isClosed: false,
    idRule: [
      v => !!v || 'Required'
    ],
  }),
  methods: {
    start: async function () {
      if (this.qcap_id) {
        try {
          this.loading = true;
          this.qcap = await api.getQcap(this.qcap_id);
          this.loading = false;
          if (!this.qcap.id) {
            this.isInvalid = true;
          } else {
            api.setEnv(this.qcap.env);
            if (this.qcap.car_id) {
              window.location.href = `https://requests.evaluate.se/${this.qcap.car_id}`;
            } else if ((this.qcap.open || this.readOnly) && this.qcap.questions && Array.isArray(this.qcap.questions) && this.qcap.questions.length > 0) {
              if (this.qcap.questions[0].currentIteration) {
                await router.push({
                  name: 'QCAP', params: {
                    qcap_id: this.qcap_id,
                    qcap: this.qcap,
                    readOnly: this.readOnly,
                  }
                });
              } else {
                this.isError = true;
              }
            } else {
              this.isClosed = true;
            }
          }
        } catch {
          this.isError = true;
        }
      }
    }
  }
};
</script>
