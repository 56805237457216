<template>
    <div id="saq_submitted">
        <v-row justify="center">
            <v-col cols="10">
                <h1 class="display-1 mb-4">{{ content.keys.answers_submitted }}</h1>
                <p>{{ content.keys.submitted_text }}</p>
                <p>{{ content.keys.may_close }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'QcapSubmitted',
        props: {
            content: Object,
        },
        mounted() {
            this.$gtag.screenview({
                app_name: 'Evaluate QCAP',
                screen_name: 'Submitted',
            });
        },
    }
</script>
