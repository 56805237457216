import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/Start.vue'
import Qcap from '../views/Qcap.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/questions',
    name: 'QCAP',
    component: Qcap,
    props: true
  },
  {
    path: '/:id',
    name: 'StartID',
    component: Start
  },
  {
    path: '/',
    name: 'Start',
    component: Start
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
