<template>
    <v-app id="qcap">

        <v-dialog v-model="dialog_welcome" @click:outside="setWelcomed" @load="setWelcomed" max-width="700px">
            <v-card>
                <v-card-title class="headline">{{ content.keys.welcome }}</v-card-title>
                <v-card-text>
                    <div class="d-flex justify-center" v-if="logos.svg || logos.png">
                        <v-img
                                :src="logos.svg || logos.png"
                                max-height="100"
                                max-width="200"
                                class="mb-4" contain></v-img>
                    </div>
                    <p>{{ content.keys.qcap_welcome_text }}</p></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" text @click="setWelcomed">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-bottom-sheet v-model="showSubmitSheet" inset>
            <v-sheet class="text-center" height="200px">
                <div class="py-6">{{ content.keys.submit_text }}</div>
                <v-btn
                        color="success"
                        @click="dialog_submit = true">
                    {{ content.keys.button_submit_answers }}
                </v-btn>
                <div>
                    <v-btn
                            class="mt-4"
                            color="secondary"
                            text
                            @click="sheet_submit = false">
                        {{ content.keys.button_not_yet }}
                    </v-btn>
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <v-dialog v-model="dialog_submit" max-width="700px">
            <v-card>
                <v-card-title class="headline">{{ content.keys.submit_answers }}</v-card-title>
                <v-card-text>
                    <p>{{ content.keys.submit_instruction }}</p>
                    <v-form ref="submitform">
                        <v-text-field
                                :label="content.keys.full_name"
                                v-model="signature"
                                :rules="requiredRule"
                                required
                                hide-details></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="dialog_submit = false">{{ content.keys.button_not_yet }}
                    </v-btn>
                    <v-btn color="success" :disabled="!signature" @click="submitQcap">{{
                        content.keys.button_submit_answers }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_help" max-width="700px">
            <v-card>
                <v-card-title class="headline">{{ content.keys.instructions }}</v-card-title>
                <v-card-text>{{ content.keys.qcap_instruction_text }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" text @click="dialog_help = false">{{ content.keys.button_close }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-app-bar app color="primary" dark src="https://cdn.ethosevaluate.net/core/assets/mesh_white_op25.svg">
            <v-toolbar-title v-text="title"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon @click.stop="dialog_help = true" v-on="on">
                        <v-icon>{{ icons.mdiHelp }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ content.keys.button_instructions }}</span>
            </v-tooltip>
            <span v-if="unsaved && !readOnly">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon :loading="saving" @click.stop="save(true)" v-on="on">
                            <v-icon>{{ icons.mdiContentSave }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ content.keys.button_save_progress }}</span>
                </v-tooltip>
            </span>
        </v-app-bar>
        <v-main>
            <v-banner v-if="banner_preview" single-line>
                Preview mode. Nothing will be saved.
                <template v-slot:actions>
                    <v-btn text color="info"
                           @click="banner_preview = false">
                        Dismiss
                    </v-btn>
                </template>
            </v-banner>
            <v-container fluid>
                <v-row no-gutters>
                    <v-col>
                        <qcap-submitted v-if="submitted" :content="content"></qcap-submitted>
                        <qcap-group v-if="!submitted && questions.length > 0" :content="content" :questions="questions"
                                    :qcap="qcap" v-on:answer="handleAnswer"></qcap-group>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col cols="10" class="d-flex justify-end">
                        <v-btn v-if="!submitted && questions.length > 0" :disabled="!submittable"
                               @click.stop="dialog_submit = true" color="success">
                            <v-icon v-if="submittable" class="mr-1">{{ icons.mdiSend }}</v-icon>
                            <v-icon v-if="!submittable" class="mr-1">{{ icons.mdiSendLock }}</v-icon>
                            {{content.keys.button_submit_answers}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <v-snackbar
                v-model="snackbar"
                :timeout="timeout">
            {{snackbarMessage}}
        </v-snackbar>

        <v-footer app color="primary" dark>
            <div class="my-1">
                <a href="https://www.evaluate.se" target="_blank">
                    <v-img position="left" src="https://cdn.ethosevaluate.net/core/assets/logo_white.svg"
                           height="30" contain></v-img>
                </a>
            </div>
            <v-spacer></v-spacer>
            <span class="white--text caption">
                &copy; {{ year }} Ethos Evaluate AB
                |
                <a href="https://tool.ethosevaluate.com/info/privacypolicy" target="_blank" style="color: #fff;">Privacy Policy</a>
            </span>
        </v-footer>

    </v-app>
</template>

<script>
    import api from '../core-api'
    import {
        mdiArrowLeft,
        mdiArrowRight,
        mdiCheckCircle,
        mdiCircleOutline,
        mdiContentSave,
        mdiHelp,
        mdiHome,
        mdiSend,
        mdiSendLock
    } from '@mdi/js'
    import QcapGroup from '../components/QcapGroup'
    import QcapSubmitted from '../components/QcapSubmitted'

    export default {
        name: 'QCAP',
        props: {
            qcap_id: String,
            qcap: Object,
            readOnly: Boolean,
        },
        components: {
            'qcap-group': QcapGroup,
            'qcap-submitted': QcapSubmitted,
        },
        mounted() {
            this.$gtag.screenview({
                app_name: 'Evaluate QCAP',
                screen_name: 'QCAP',
            });
        },
        async created() {
            if (this.qcap_id && this.qcap.id) {
                if (this.readOnly) {
                    this.banner_preview = true;
                }
                if (this.qcap.status) {
                    if (this.qcap.status === 'invited') {
                        this.qcap.status = 'viewed';
                        this.qcap.ts_viewed = new Date();
                        await this.save(false, true);
                    } else if (this.qcap.status === 'submitted') {
                        this.submitted = true;
                    }
                }
                if (this.qcap.logos) {
                    this.logos = this.qcap.logos;
                    delete this.qcap.logos;
                }
                if (this.qcap.saq_title) {
                    this.saq_title = this.qcap.saq_title;
                    delete this.qcap.saq_title;
                }
                if (this.qcap.company) {
                    this.company = this.qcap.company;
                    delete this.qcap.company;
                }
                this.questions = this.qcap.questions;
                this.lang = this.qcap.lang;
                if (this.qcap.welcomed) {
                    this.welcomed = true;
                }
                if (this.lang !== 'en') {
                    this.content.translations = await api.getTranslations(this.lang);
                    this.translate();
                }
                if (this.content.translations.qcap_title) {
                    this.qcap_title = this.content.translations.qcap_title;
                }
                this.checkDone();
                this.startAutoSave();
            } else {
                window.location.href = '/';
            }
        },
        watch: {
            unsaved: function () {
                if (this.unsaved) {
                    window.onbeforeunload = function () {
                        return true;
                    };
                } else {
                    window.onbeforeunload = null;
                }
            }
        },
        computed: {
            dialog_welcome() {
                return !this.welcomed && !this.submitted && this.qcap.id;
            },
            showSubmitSheet: {
                get() {
                    return this.sheet_submit && !this.dialog_submit;
                },
                set(value) {
                    this.sheet_submit = value;
                },
            },
            title() {
                let t = '';
                if (this.saq_title) {
                    t = this.content.keys.followup_of.concat(' ', this.saq_title);
                } else {
                    t = this.qcap_title;
                }
                if (this.company) {
                    t = this.company.concat(' – ', t);
                }
                return t;
            },
        },
        data: () => ({
            qcap_title: 'Questionnaire Follow-up',
            saq_title: '',
            logos: {},
            company: '',
            year: new Date().getFullYear().toString(),
            questions: [],
            timeout: 3000,
            snackbar: false,
            snackbarMessage: '',
            banner_preview: false,
            unsaved: false,
            loading: false,
            dialog_submit: false,
            sheet_submit: false,
            signature: '',
            submittable: false,
            submitted: false,
            saving: false,
            autosave: null,
            dialog_help: false,
            welcomed: false,
            requiredRule: [
                v => !!v || 'Required',
            ],
            content: {
                keys: {
                    followup_of: 'Follow-up of',
                    followup_questions: 'Follow-up questions',
                    validation_required_text: 'Required',
                    welcome: 'Welcome',
                    qcap_welcome_text: 'Please answer all of the follow-up questions and upload any applicable documents if requested. Your answers are saved continuously. When done, submit the answers for review.',
                    full_name: 'Full name',
                    cancel: 'Cancel',
                    save: 'Save',
                    button_cancel: 'Cancel',
                    button_save: 'Save',
                    add: 'Add',
                    new: 'New',
                    edit: 'Edit',
                    button_ok: 'Okay',
                    button_proceed: 'Proceed',
                    button_yes: 'Yes',
                    button_no: 'No',
                    button_add: 'Add',
                    button_new: 'New',
                    button_edit: 'Edit',
                    button_not_yet: 'Not yet',
                    button_close: 'Close',
                    confirm_generic: 'Are you sure?',
                    reset: 'Reset',
                    button_reset: 'Reset',
                    button_continue: 'Continue',
                    button_submit_answers: 'Submit answers',
                    button_show_progress: 'Show progress',
                    button_instructions: 'Instructions',
                    button_save_progress: 'Save progress',
                    button_upload: 'Upload',
                    button_start: 'Start',
                    delete_file: 'Delete file',
                    instructions: 'Instructions',
                    submit_answers: 'Submit your answers',
                    submit_text: 'You have answered all the questions and are ready to submit the answers.',
                    submit_instruction: 'To submit, sign the answers by entering your full name.',
                    submission_commitment: 'I hereby confirm that all answers have been given according to my best knowledge and belief.',
                    qcap_instruction_text: 'Please answer all of the follow-up questions and upload any applicable documents if requested. Your answers are saved continuously. When done, submit the answers for review.',
                    answers_submitted: 'Your answers are submitted',
                    submitted_text: 'The answers will be reviewed and evaluated. In the case that we need even more information, you may be asked to answer additional follow-up questions. Thank you again for your participation!',
                    may_close: 'You can now close this window.',
                    placeholder_text_answer: 'Write answer here',
                    placeholder_files: 'Select files...',
                    actions: 'Actions',
                    notification_upload_success: 'The file upload was successful.',
                    notification_upload_failure: 'The file upload failed. Please try again.',
                    uploaded_files: 'Uploaded files',
                },
                translations: {},
            },
            icons: {
                mdiCheckCircle,
                mdiCircleOutline,
                mdiContentSave,
                mdiHome,
                mdiSend,
                mdiSendLock,
                mdiHelp,
                mdiArrowLeft,
                mdiArrowRight
            }
        }),
        methods: {
            translate() {
                if (Object.keys(this.content.translations).length > 0) {
                    for (const tk of Object.keys(this.content.translations)) {
                        if (tk.indexOf('-') === -1) {
                            this.content.keys[tk] = this.content.translations[tk];
                        }
                        delete this.content.translations[tk];
                    }
                }
            },
            setWelcomed() {
                this.welcomed = true;
                this.qcap.welcomed = true;
            },
            startAutoSave() {
                this.autosave = setInterval(() => {
                    if (this.unsaved) {
                        this.save(true);
                    }
                }, 15000);
            },
            handleAnswer(triggerSave) {
                this.unsaved = true;
                if (triggerSave) {
                    this.save(false);
                }
                this.checkDone();
            },
            checkDone() {
                try {
                    let t = 0, td = 0;
                    for (const question of this.qcap.questions) {
                        t += 1;
                        if (question.currentIteration.answered) {
                            td += 1;
                        }
                    }
                    this.submittable = (td === t);
                    setTimeout(() => {
                        this.sheet_submit = this.submittable;
                    }, 10000);
                } catch {
                    // Not handled
                }
            },
            scrollToTop() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            },
            async save(show, skipStarted) {
                let ret = false;
                if (!this.readOnly) {
                    this.saving = true;
                    if (!this.qcap.ts_viewed) {
                        this.qcap.ts_viewed = new Date();
                    }
                    if (!skipStarted && !this.qcap.ts_started && this.qcap.status !== 'submitted') {
                        this.qcap.status = 'started';
                        this.qcap.ts_started = new Date();
                    } else if (!skipStarted && !this.qcap.ts_started) {
                        this.qcap.ts_started = new Date();
                    }
                    const result = await api.saveQcap(this.qcap.id, this.qcap);
                    if (!result.success) {
                        this.snackbarMessage = 'Unable to save progress.';
                        this.snackbar = true;
                        ret = true;
                    } else if (show) {
                        this.snackbarMessage = 'Your progress was saved.';
                        this.snackbar = true;
                        this.unsaved = false;
                    }
                    this.saving = false;
                }
                return ret;
            },
            async submitQcap() {
                this.$refs.submitform.validate();
                if (this.submittable && this.signature && !this.readOnly) {
                    this.dialog_submit = false;
                    this.qcap.status = 'submitted';
                    this.qcap.signature = this.signature;
                    this.qcap.ts_submitted = new Date();
                    try {
                        await this.save(false);
                        this.submitted = true;
                    } catch (err) {
                        console.error('submit', err);
                    }
                }
            }
        }
    }
</script>

<style>
</style>
